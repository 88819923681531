import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaEnvelope, FaKey, FaChevronDown, FaListAlt, FaTools } from 'react-icons/fa';
import './ContractorEditModal.css';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://dev-api-7jrujiqd5q-uc.a.run.app';
const API_KEY = process.env.REACT_APP_API_KEY;

function ContractorEditModal({ contractor, onSave, onClose, onPasswordReset }) {
  const [editedContractor, setEditedContractor] = useState(contractor);
  const [categories, setCategories] = useState({});
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [error, setError] = useState(null);
  const modalRef = useRef(null);
  const addServiceRef = useRef(null);
  const [activeView, setActiveView] = useState('details');
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [showSelectedServices, setShowSelectedServices] = useState(false);
  const [serviceSearch, setServiceSearch] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoadingCategories(true);
        setError(null);
        const response = await fetch(`${BASE_URL}/searchv2/services/category/all`, {
          headers: {
            'X-API-KEY': API_KEY || '',
          },
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(error.message);
      } finally {
        setIsLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleInputChange = (field, value) => {
    setEditedContractor(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleConInfoChange = (field, value) => {
    setEditedContractor(prev => ({
      ...prev,
      conInfo: {
        ...prev.conInfo,
        [field]: value
      }
    }));
  };

  const handleServiceToggle = (service) => {
    const services = editedContractor.conInfo?.selectedServices || [];
    const updatedServices = services.includes(service)
      ? services.filter(s => s !== service)
      : [...services, service];
    handleConInfoChange('selectedServices', updatedServices);
  };

  const handlePasswordResetClick = () => {
    onPasswordReset(editedContractor.email);
  };

  const handleAddServiceClick = () => {
    if (addServiceRef.current) {
      addServiceRef.current.focus();
      addServiceRef.current.click();
    }
  };

  const toggleCategory = (category) => {
    const newExpanded = new Set(expandedCategories);
    if (newExpanded.has(category)) {
      newExpanded.delete(category);
    } else {
      newExpanded.add(category);
    }
    setExpandedCategories(newExpanded);
  };

  const handleCategorySelect = (category, services) => {
    const currentServices = new Set(editedContractor.conInfo?.selectedServices || []);
    const categoryServices = new Set(services);
    
    // Check if all services in category are selected
    const allSelected = services.every(service => currentServices.has(service));
    
    // Toggle all services in category
    const updatedServices = new Set(currentServices);
    services.forEach(service => {
      if (allSelected) {
        updatedServices.delete(service);
      } else {
        updatedServices.add(service);
      }
    });
    
    handleConInfoChange('selectedServices', Array.from(updatedServices));
  };

  const isServiceAvailable = (service) => {
    return Object.values(categories).some(categoryServices => 
      categoryServices.includes(service)
    );
  };

  const getCategorySelectionStatus = (categoryServices) => {
    const selectedServices = new Set(editedContractor.conInfo?.selectedServices || []);
    const selectedCount = categoryServices.filter(service => selectedServices.has(service)).length;
    
    if (selectedCount === 0) return 'none';
    if (selectedCount === categoryServices.length) return 'all';
    return 'partial';
  };

  const getFilteredCategories = () => {
    if (!serviceSearch) return categories;
    
    return Object.entries(categories).reduce((acc, [category, services]) => {
      const filteredServices = services.filter(service => 
        service.toLowerCase().includes(serviceSearch.toLowerCase())
      );
      if (filteredServices.length > 0) {
        acc[category] = filteredServices;
      }
      return acc;
    }, {});
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Edit Contractor</h2>
          <div className="view-toggle">
            <button 
              className={`view-button ${activeView === 'details' ? 'active' : ''}`}
              onClick={() => setActiveView('details')}
            >
              <FaListAlt />
              <span>Details</span>
            </button>
            <button 
              className={`view-button ${activeView === 'services' ? 'active' : ''}`}
              onClick={() => setActiveView('services')}
            >
              <FaTools />
              <span>Services</span>
            </button>
          </div>
          <button className="close-button" onClick={onClose}><FaTimes /></button>
        </div>

        <div className="modal-body">
          {activeView === 'details' ? (
            <div className="form-grid">
              <div className="form-column">
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    value={editedContractor.conInfo?.companyName || ''}
                    onChange={(e) => handleConInfoChange('companyName', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Display Name</label>
                  <input
                    type="text"
                    value={editedContractor.conInfo?.displayName || ''}
                    onChange={(e) => handleConInfoChange('displayName', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    value={editedContractor.conInfo?.address1 || ''}
                    onChange={(e) => handleConInfoChange('address1', e.target.value)}
                  />
                </div>
              </div>
              <div className="form-column">
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    value={editedContractor.fullname || ''}
                    onChange={(e) => handleInputChange('fullname', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    value={editedContractor.email || ''}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <button className="reset-password-button" onClick={handlePasswordResetClick}>
                    <FaKey />
                    <span>Send Reset Link</span>
                  </button>
                </div>
              </div>
              <div className="bio-short-inputs-container">
                <div className="bio-container">
                  <div className="form-group">
                    <label>Bio</label>
                    <textarea
                      value={editedContractor.conInfo?.bio || ''}
                      onChange={(e) => handleConInfoChange('bio', e.target.value)}
                    />
                  </div>
                </div>
                <div className="short-inputs-grid">
                  <div className="short-input">
                    <label>Distance</label>
                    <input
                      type="text"
                      value={editedContractor.conInfo?.travelDistance || ''}
                      onChange={(e) => handleConInfoChange('travelDistance', e.target.value)}
                      maxLength="2"
                    />
                  </div>
                  <div className="short-input">
                    <label>Experience</label>
                    <input
                      type="text"
                      value={editedContractor.conInfo?.yearsOfService || ''}
                      onChange={(e) => handleConInfoChange('yearsOfService', e.target.value)}
                      maxLength="2"
                    />
                  </div>
                  <div className="short-input">
                    <label>Insurance</label>
                    <select
                      value={editedContractor.conInfo?.insurance || ''}
                      onChange={(e) => handleConInfoChange('insurance', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className="short-input">
                    <label>Workers Comp</label>
                    <select
                      value={editedContractor.conInfo?.workersComp || ''}
                      onChange={(e) => handleConInfoChange('workersComp', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="services-view">
              <div className="selected-services-summary">
                <div 
                  className="summary-header"
                  onClick={() => setShowSelectedServices(!showSelectedServices)}
                >
                  <h3>
                    <FaChevronDown className={`arrow ${showSelectedServices ? 'open' : ''}`} />
                    Selected Services ({editedContractor.conInfo?.selectedServices?.length || 0})
                  </h3>
                </div>
                {showSelectedServices && (
                  <div className="selected-services-tags">
                    {editedContractor.conInfo?.selectedServices?.map(service => (
                      <span 
                        key={service} 
                        className={`service-tag ${!isServiceAvailable(service) ? 'unavailable' : ''}`}
                      >
                        {service}
                        <FaTimes onClick={() => handleServiceToggle(service)} />
                      </span>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="services-search">
                <input
                  type="text"
                  placeholder="Search services..."
                  value={serviceSearch}
                  onChange={(e) => setServiceSearch(e.target.value)}
                />
              </div>

              <div className="service-categories-list">
                {!isLoadingCategories && Object.entries(getFilteredCategories()).map(([category, services]) => (
                  <div key={category} className="category-section">
                    <div 
                      className="category-header"
                      onClick={() => toggleCategory(category)}
                    >
                      <div className="category-title">
                        <FaChevronDown className={`arrow ${expandedCategories.has(category) ? 'open' : ''}`} />
                        <span>{category}</span>
                        <div className="selection-status">
                          {(() => {
                            const status = getCategorySelectionStatus(services);
                            const count = services.filter(service => 
                              editedContractor.conInfo?.selectedServices?.includes(service)
                            ).length;
                            
                            return (
                              <>
                                <div className={`status-indicator ${status}`} />
                                <span className="status-count">
                                  {count}/{services.length}
                                </span>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                      <button 
                        className={`select-all-button ${getCategorySelectionStatus(services)}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategorySelect(category, services);
                        }}
                      >
                        {getCategorySelectionStatus(services) === 'all' ? 'Deselect All' : 'Select All'}
                      </button>
                    </div>
                    
                    {expandedCategories.has(category) && (
                      <div className="category-services">
                        {services.map(service => (
                          <div 
                            key={service}
                            className={`service-item ${editedContractor.conInfo?.selectedServices?.includes(service) ? 'selected' : ''}`}
                            onClick={() => handleServiceToggle(service)}
                          >
                            {service}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="button-group">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="save-button" onClick={() => onSave(editedContractor)}>Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default ContractorEditModal;